/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer3 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-electri-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.electri}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Mezoterapia igłowa skóry wokół oczu - kwas hialuronowy
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="electri Mezoterapia kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>
              Wskazania do mezoterapii igłowej skóry wokół oczu
            </Subtitle>
            <Paragraph>
              Preparat medyczny do iniekcji{' '}
              <strong>
                na bazie nieusieciowanego kwasu hialuronowego i bursztynowego
              </strong>{' '}
              jako główny zastrzyk z szybkimi wynikami. Służy do intensywnej
              regeneracji skóry, który już po jednym zabiegu przynosi szybki
              efekt w krótkim czasie. Dzięki fizjologicznemu stężeniu
              wysokocząsteczkowego kwasu hialuronowego 0,55% nie zostawia
              grudek, idealnie nadaje się dla kobiet i mężczyzn, którzy już po
              jednym zabiegu chcą uzyskać efekt odmłodzenia skóry bez
              długotrwałego okresu rehabilitacji
            </Paragraph>
            <Paragraph>
              Najczęściej stosowany jest jako zabieg mezoterapii pod oczy. Jest
              alternatywą dla pacjentów, którzy nie kwalifikują się do
              wypełnienia doliny łez kwasem hialuronowym, mają pierwsze cechy
              starzenia skóry pod oczami, drobne zmarszczki, zasinienia, zmiany
              depigmentacyjne.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Opis zabiegu mezoterapii igłowej skóry wokół oczu
            </Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. Produkt podaje się w formie iniekcji w skórę
              która utraciły kolagen i elastynę. Po wyjaśnieniu przebiegu
              zabiegu, podpisaniu zgody przez Pacjenta i dezynfekcji skóry
              wykonuje się iniekcje mikrodepozytów. Produkt można też podawać za
              pomocą kaniuli co redukuje ryzyko wystąpienia siniaczków po
              zabiegu.
            </Paragraph>
            <Paragraph>
              Zabieg wykonuje się w miejscu wcześniej znieczulonym przy pomocy
              specjalnego kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty?</Subtitle>
            <Paragraph>
              Pierwsze efekty zabiegu widoczne są już po kilku dniach od
              zabiegu. W celu kompleksowego odmłodzenia skóry najczęściej
              wykonuje się serię 4 zabiegów, w odstępie ok. 10-14 dni. Zdjęcia
              ukazują efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może
              się różnić w zależności od indywidualnych cech pacjenta, liczby
              powtórzeń zabiegu, stosowania się pacjenta do zaleceń
              pozabiegowych oraz umiejętności i doświadczenia osoby
              przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>
              Zdjęcia przed po mezoterapią igłową skóry wokół oczy
            </Subtitle>
            <SImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="electri mezoterapia igłowa skóry wokół oczu przed po"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena mezoterapii igłowej skóry wokół oczu</Subtitle>
            <Paragraph>
              Cena zabiegu zaczyna się od 700 zł. Przy pierwszej wizycie odbierz
              swoją kartę lojalnościową i otrzymaj -30% na czwarty zabieg
              medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>
              Przeciwwskazania do mezoterapii igłowej skóry wokół oczu
            </Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Co to jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrności,
              elastyczności i gładkości skóry.
            </Paragraph>
            <Paragraph>
              Wraz z upływem czasu, produkcja naturalnego kwasu hialuronowego w
              organizmie zmniejsza się, co może prowadzić do utraty nawilżenia i
              powstawania zmarszczek. W związku z tym, kwas hialuronowy jest
              szeroko wykorzystywany w kosmetologii i medycynie estetycznej.
              Może być stosowany w postaci zastrzyków lub jako składnik
              kosmetyków, takich jak kremy, serum czy maski, w celu nawilżenia
              skóry, redukcji zmarszczek i poprawy ogólnego wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, kwas hialuronowy może być stosowany w medycynie, na
              przykład w leczeniu chorób stawów, takich jak osteoartroza, gdzie
              wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia bólu i
              poprawy funkcji stawu.
            </Paragraph>
            <Paragraph>
              Ważne jest, aby skonsultować się z lekarzem przed rozpoczęciem
              jakiejkolwiek terapii związanej z kwasem hialuronowym, aby
              dostosować dawkowanie i określić odpowiednie metody stosowania w
              zależności od indywidualnych potrzeb.
            </Paragraph>
            <Subtitle>Kwas hialuronowy w medycynie estetycznej</Subtitle>
            <Paragraph>
              Kwas hialuronowy jest jednym z najpopularniejszych składników
              stosowanych w medycynie estetycznej. Ma wiele zastosowań w
              poprawianiu wyglądu skóry i przeciwdziałaniu procesom starzenia.
            </Paragraph>
            <Paragraph>
              Jednym z głównych zastosowań kwasu hialuronowego w medycynie
              estetycznej jest redukcja zmarszczek i linii mimicznych. Kiedy
              kwas hialuronowy jest wstrzykiwany pod skórę, wypełnia ubytki i
              płytkie zmarszczki, nadając skórze gładki i bardziej młodzieńczy
              wygląd. Może być stosowany na różnych obszarach twarzy, takich jak
              linie wokół ust (tzw. zmarszczki palacza), zmarszczki
              nosowo-wargowe, linie marionetki czy zmarszczki czołowe.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy może również być stosowany do konturowania i
              modelowania twarzy. Może pomóc w podkreśleniu kości policzkowych,
              kształtowaniu brody, podniesieniu opadających kącików ust czy
              wygładzeniu podbródka. Wstrzyknięcie kwasu hialuronowego w
              odpowiednie miejsca pozwala na tworzenie subtelnych zmian w
              proporcjach twarzy i poprawę jej symetrii.
            </Paragraph>
            <Paragraph>
              Innym zastosowaniem kwasu hialuronowego jest poprawa objętości i
              nawilżenia skóry. Może być stosowany do wypełnienia ubytków,
              takich jak blizny, zagłębienia czy asymetrie. Dodatkowo, może
              pomóc w poprawie elastyczności i jędrności skóry, przynosząc efekt
              odmłodzenia.
            </Paragraph>
            <Paragraph>
              Procedury związane z kwasem hialuronowym w medycynie estetycznej
              są zwykle stosunkowo bezpieczne i dają natychmiastowe rezultaty.
              Efekty utrzymują się przez okres kilku miesięcy, w zależności od
              typu kwasu hialuronowego, miejsca wstrzyknięcia oraz indywidualnej
              reakcji organizmu. Istnieje również możliwość rozpuszczenia kwasu
              hialuronowego za pomocą enzymów, jeśli pacjent nie jest zadowolony
              z rezultatów lub jeśli zachodzi konieczność korekty.
            </Paragraph>
            <Paragraph>
              Należy pamiętać, że procedury związane z kwasem hialuronowym
              powinny być wykonywane przez doświadczonego specjalistę, takiego
              jak lekarz medycyny estetycznej, dermatolog lub chirurg, który ma
              wiedzę i umiejętności w zakresie bezpiecznego i skutecznego
              stosowania kwasu hialuronowego.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer3;

export const Head = () => <SEO tags={TAGS.electri} />;
